.masthead.segment {
  min-height: 300px;
  max-width: 964px;
  padding: 1em 0em;
  background-image: url("/src/images/facade-banner.jpg");
  background-color: #000000;
  background-position: right;
}

.masthead .header.huge {
  font-size: 2.5em;
  padding: 0em 0.25em;
  font-weight: normal;
  color: #ffffff;
  text-shadow: 2px 2px #000000;
  vertical-align: bottom;
}

.row1image {
  min-height: 200px;
  width: 365px;
  padding: 0.5em 0.5em;
  background-color: #000000;
  background-position: center;
  color: #ffffff;
  text-shadow: 2px 2px #000000;
  vertical-align: bottom;
  font-size: 1.5em;
  font-weight: normal;
}

.row1image.yearbookimage {
  background-image: url("/src/images/1981_yearbook.jpg");
}
.row1image.lamsonimage {
  background-image: url("/src/images/lamson_over_353.jpg");
}
